<template>
  <secondary-overlay class="lg:px-20 px-10 pt-10 pb-20">
    <img src="@/assets/images/stills/D630849B-CCA2-4859-9B2B-AE3D9BF40A32.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL1.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL2.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL3.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL4.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL5.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL6.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL7.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL8.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL9.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL10.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL11.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL12.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL13.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL14.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL15.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL16.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL17.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL18.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL19.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL20.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL21.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL22.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL23.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL24.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL25.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL26.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL27.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL28.jpg" alt="Image still from movie" />
    <img src="@/assets/images/stills/LDSJ_STILL29.jpg" alt="Image still from movie" />
  </secondary-overlay>
</template>

<script>
import SecondaryOverlay from "../components/SecondaryOverlay.vue";
export default {
  components: { SecondaryOverlay },
  methods: {},
};
</script>
<style lang="scss" scoped>
img {
  width:100%;
  height:auto;
  display: block;
  margin: auto;
  padding-bottom: 2em;
  max-width: 100%;
}
</style>
